export default () => {
  function readURL(input) {
    if (input && input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $("#js-upload-box").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
        $("#js-upload-box").addClass("has-photo");
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  window.readURL = input => readURL(input);
};
