export default () => {
  const inputs = Array.from(document.querySelectorAll('.animated-field input:not([value]), .animated-field input[value=""]'));

  inputs.forEach(function(input) {
    input.classList.add("empty");

    ["keyup", "input", "change"].forEach(function(event) {
      input.addEventListener(event, function() {
        if (!!this.value) {
          this.classList.remove("empty");
        } else {
          this.classList.add("empty");
        }
      });
    });
  });
};
