import Shared from "./shared/index";

document.addEventListener("turbolinks:load", function() {
  Shared();

  $("#new_crew_member").validate({
    rules: {
      'verify_email': {
        equalTo : "#crew_member_email"
      }
    },
    messages:{
      "verify_email": "Verify email doesnt match"
    },
    highlight: function (element) {
      $(element).addClass('is-invalid');
    },
    unhighlight: function (element) {
      $(element).removeClass('is-invalid');
      $(element).attr('data-original-title','');
    },
    errorPlacement: function (error, element) {
      element.attr("data-toggle", "tooltip");
      element.attr("data-original-title", error[0].innerHTML);
      error.css({
        'color': '#FF0000',
      });
      $('.is-invalid').tooltip();
      return false;
    }
  });
});
