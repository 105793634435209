export default () => {
  // Show / hide the nav on mobile
  $("#js-showNav").on("click", function() {
    $("#js-nav").addClass("active");
    $("body").addClass("show-overlay");
    $("body").bind("touchmove", function(e) {
      e.preventDefault();
    });
  });

  $("#js-hideNav").on("click", function() {
    $("#js-nav").removeClass("active");
    $("body").removeClass("show-overlay");
    $("body").unbind("touchmove");
  });
};
