export default autoSubmit;

function autoSubmit() {
  const forms = document.querySelectorAll("form[data-auto-submit=true]");
  for (let i = 0; i < forms.length; i++) {
    Array.from(forms[i].querySelectorAll("input, select, textarea")).map(
      input =>
        (input.oninput = function() {
          $(forms[i]).trigger("submit.rails"); // If argument autoSubmit true fire rails remote submit

          if ("createEvent" in document) {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("submit", false, true);
            forms[i].dispatchEvent(evt);
          } else forms[i].fireEvent("onsubmit");
        })
    );
  }
}

window.autoSubmitInit = autoSubmit;
