import fileReader from "./file-input";
import nav from "./nav";
import autosubmit from "./autosubmit";
import handleInputs from "./input-handling";

export default () => {
  fileReader();
  nav();
  autosubmit();
  handleInputs();
};
